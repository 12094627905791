import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FileUploader } from 'ng2-file-upload';
import { CurrencyMaskInputMode } from 'ngx-currency';
import { Subscription, take } from 'rxjs';
import { ChampionService } from 'src/app/_core/api/champion.service';
import { GeneralService } from 'src/app/_core/api/general.service';
import { ChampionStatus, ENGAGEMENT_MONTHS, TESTIMONIAL_EXPIRATION_OPTIONS } from 'src/app/_core/constants/Champion';
import { ENTITY_TYPE } from 'src/app/_core/constants/CompanyOrFirm';
import { EnvironmentName } from 'src/app/_core/constants/EnvironmentConst';
import { FileType } from 'src/app/_core/constants/FileType';
import { MODAL_ACTIONS, Modals } from 'src/app/_core/constants/Modals';
import { ToastrMessages } from 'src/app/_core/constants/ToastrMessages';
import Urls from 'src/app/_core/constants/Urls';
import { UserStatus } from 'src/app/_core/constants/User';
import ComputePayloadHelper from 'src/app/_core/helpers/ComputePayload.helper';
import Utils from 'src/app/_core/helpers/Utils';
import { AllowedFileTypesMessage } from 'src/app/_core/models/FileInfo';
import { Media } from 'src/app/_core/models/FormControls';
import { GSFormControl } from 'src/app/_core/models/forms/GSFormControl';
import { GSFormGroup } from 'src/app/_core/models/forms/GSFormGroup';
import { generateGSFormElement } from 'src/app/_core/models/forms/Model';
import { ResponseObject, UUIDName } from 'src/app/_core/models/GenericObject';
import { ModalEvent } from 'src/app/_core/models/Modal';
import { UploadPromiseResponse } from 'src/app/_core/models/UploadResponse';
import { UserDetails } from 'src/app/_core/models/User';
import { DropdownsService } from 'src/app/_core/services/dropdowns.service';
import { GlobalConfigService } from 'src/app/_core/services/global-config.service';
import { ModalsService } from 'src/app/_core/services/modals.service';
import { PortfolioDataService } from 'src/app/_core/services/portfolio-data.service';
import { ToastService } from 'src/app/_core/services/toast.service';
import { UploadService } from 'src/app/_core/services/upload.service';
import { UserService } from 'src/app/_core/services/user.service';
import { Champion2, ChampionEngagement, ChampionMedia, IChampionResponse } from 'src/app/internal-app/details/champion/models/Champion';
import { CHAMPION_CONTROLS_2, ChampionForm2 } from 'src/app/internal-app/details/champion/models/ChampionFormControls';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-champion-form',
  templateUrl: './champion-form.component.html',
  styleUrls: ['./champion-form.component.scss'],
})
export class ChampionFormComponent implements OnInit, OnDestroy {
  @Input() viewOnly: boolean = false;
  @Input() entityUuid: string;
  @Input() donorChampionView: boolean = false;

  organizationName: string;
  entityName: string;
  currentUser: UserDetails;
  championControls = CHAMPION_CONTROLS_2;
  championForm: GSFormGroup<Champion2>;
  hasChampionEntity: boolean = false;
  engagementYears: UUIDName[] = [];
  engagementMonths: UUIDName[] = ENGAGEMENT_MONTHS;
  testimonialExpirations: UUIDName[] = TESTIMONIAL_EXPIRATION_OPTIONS;
  showGrantsAwarded: boolean;
  thousandsSeparator: string;
  decimalSeparator: string;
  inputMode: CurrencyMaskInputMode = CurrencyMaskInputMode.NATURAL;
  currencies: UUIDName[];
  uploader: FileUploader;
  allowedFileTypes = new AllowedFileTypesMessage();
  finished = false;
  submitted = false;
  championUuid: string;
  entityType: ENTITY_TYPE;
  headerText: string;
  headerUpdateText: string;
  isTgf: boolean = true;
  champion: Champion2;
  userFullName: string;
  yearRange: number = 0;
  previousURL: string;
  modalResponse: Subscription;
  uploaderSubscription: Subscription;
  unsubscribes: Subscription[] = [];
  selectedStatus: string;
  showSaveAsDraft: boolean = false;
  showSubmitBtn: boolean = false;
  hasExistingData: boolean = false;

  constructor(
    private userService: UserService,
    private dropdowns: DropdownsService,
    private uploadService: UploadService,
    private modalsService: ModalsService,
    private toastService: ToastService,
    private championService: ChampionService,
    private portfolioDataService: PortfolioDataService,
    private activatedRoute: ActivatedRoute,
    private globalConfigService: GlobalConfigService,
    private router: Router,
    private generalService: GeneralService,
    private translateService: TranslateService
  ) {
    this.showGrantsAwarded = this.globalConfigService.globalConfig.displayGrantAwardedFieldsInChampionForm;
    this.dropdowns.dropdowns$.subscribe((res) => (this.currencies = res.currencies as unknown as UUIDName[]));
    this.uploader = this.uploadService.uploaderInstance;
    this.selectedStatus = this.activatedRoute.snapshot.queryParams.status;
    this.championUuid = this.activatedRoute.snapshot.queryParams.championUuid ?? this.activatedRoute.snapshot.params.id;
    this.uploaderSubscription = this.uploadService.uploaderObservable.subscribe((data) =>
      data.progress ? this.setProgress(data.result) : this.setFileFields(data.result)
    );
    this.modalResponse = this.modalsService.modalResponse$.subscribe((response: ModalEvent) => {
      if (response) {
        this.handleModalResponse(response);
      }
    });
    this.unsubscribes.push(this.uploaderSubscription);
    this.unsubscribes.push(this.modalResponse);
  }

  ngOnInit(): void {
    this.yearRange = this.globalConfigService.globalConfig.yearRange;
    this.computeEngagementYears();
    this.currentUser = this.userService.currentUser;
    this.isTgf = environment.name === EnvironmentName.TB;
    this.setEntityType();
    if (this.viewOnly || this.selectedStatus !== ChampionStatus.DRAFT) {
      this.getChampionTestimonial();
    } else {
      this.getChampionDraftData();
    }

    this.showSaveAsDraft = this.selectedStatus === ChampionStatus.DRAFT;
    this.showSubmitBtn = this.selectedStatus === ChampionStatus.PENDING;

    this.thousandsSeparator = Utils.getThousandsSeparator();
    this.decimalSeparator = Utils.getDecimalSeparator();
  }

  ngOnDestroy(): void {
    this.unsubscribes.forEach((sub) => sub.unsubscribe());
  }

  generateForm(): void {
    this.championForm = generateGSFormElement(ChampionForm2, this.champion) as GSFormGroup<Champion2>;
    if (!this.grantsAwardedCurrency2.value) {
      this.grantsAwardedCurrency2.setValue(this.currencies.find((item) => item.uuid === this.globalConfigService.globalConfig.defaultCurrency?.uuid));
    }

    if (!this.testimonialVisibility.value) {
      this.testimonialVisibility.setValue(this.testimonialExpirations.find((item) => item.uuid === '12'));
    }

    if (!this.testimonial2.value && !this.championUuid) {
      this.translateService
        .get('CATALOG.CHAMPION.NEW_CHAMPION_FORM.VALUES.TESTIMONIAL')
        .pipe(take(1))
        .subscribe((text: string) => {
          this.testimonial2.setValue(text);
        });
    }

    if (this.viewOnly) {
      this.testimonial2.disable();
    }
  }

  getChampionDraftData(): void {
    const payload = {
      uuid: this.entityUuid,
      uuid2: this.currentUser.userUuid,
    };

    const getChampionDraft$ = this.championService.getChampionDraft(payload).subscribe({
      next: (res: ResponseObject<IChampionResponse>) => {
        if (res) {
          this.champion = new Champion2(res.response);
          this.hasExistingData = !!this.champion;
          this.entityName = this.champion.entityName;
          this.hasChampionEntity = !!this.champion.entityName;
          this.organizationName = this.champion.organizationName;
          this.userFullName = this.champion.personalName;

          if (!this.hasExistingData) {
            const uploaderObservables$ = this.uploadService.uploaderObservable.subscribe({
              next: (event) => {
                if (event.result.fileItem.isUploaded) {
                  setTimeout(() => {
                    this.saveDraft(true);
                  }, 1500);
                }
              },
            });

            this.unsubscribes.push(uploaderObservables$);
          }
        }
        this.generateForm();
        this.championForm.markAsDirty();
      },
      error: () => {
        this.toastService.error(ToastrMessages.BASIC_ERROR);
      },
    });

    this.unsubscribes.push(getChampionDraft$);
  }

  getChampionTestimonial(): void {
    const getChampionViewDetails$ = this.championService.getChampionViewDetails(this.championUuid).subscribe({
      next: (res: ResponseObject<IChampionResponse>) => {
        if (res) {
          this.champion = new Champion2(res.response);
          this.hasExistingData = !!this.champion;
          this.entityName = this.champion.entityName;
          this.hasChampionEntity = !!this.champion.entityName;
          this.organizationName = this.champion.organizationName;
          this.userFullName = this.champion.personalName;

          if (!this.hasExistingData) {
            const uploaderObservables$ = this.uploadService.uploaderObservable.subscribe({
              next: (event) => {
                if (event.result.fileItem.isUploaded) {
                  setTimeout(() => {
                    this.saveDraft(true);
                  }, 1500);
                }
              },
            });

            this.unsubscribes.push(uploaderObservables$);
          }
        }
        this.generateForm();
        this.championForm.markAsDirty();
      },
      error: () => {
        this.toastService.error(ToastrMessages.BASIC_ERROR);
      },
    });

    this.unsubscribes.push(getChampionViewDetails$);
  }

  setEntityType(): void {
    this.entityType = ENTITY_TYPE.ORGANISATION;
    this.headerText = 'CATALOG.CHAMPION.GENERAL.CHAMPION_THIS_ORGANISATION';
    this.headerUpdateText = 'CATALOG.CHAMPION.GENERAL.CHAMPION_UPDATE_ORGANISATION';
  }

  setCurrencySymbol(revenueCurrency: AbstractControl, currencies: Array<UUIDName>) {
    return Utils.setCurrencySymbol(revenueCurrency, currencies);
  }

  compareWithFn(o1: UUIDName, o2: UUIDName): boolean {
    return o1?.uuid === o2?.uuid || o1 === o2;
  }

  mediaSelected(event) {
    const file = this.uploader.queue[this.uploader.queue.length - 1];
    if (Utils.checkFileType(file.file.name, file.file.type, FileType.VIDEO)) {
      this.uploadService.uploadFileV3(this.video, this.entityUuid);
    } else if (Utils.checkFileType(file.file.name, file.file.type, FileType.IMG)) {
      this.openCropModal(this.image, event.target.files);
    } else {
      this.toastService.error(ToastrMessages.INVALID_FORMAT);
    }
  }

  openCropModal(mediaControl: GSFormControl, fileEvent?: HTMLInputElement) {
    const aspectRatio = 16 / 9;
    this.modalsService.openModal(Modals.CROP_IMAGE, { mediaControl, control: this.mediaControls, aspectRatio, fileEvent });
  }

  deleteChampionMediaFile(mediaControl: GSFormControl, elementRef?: HTMLInputElement): void {
    const payload = { mediaControl, elementRef };
    this.modalsService.openModal(Modals.DELETE_CONFIRMATION, {
      message: 'SHARED.MODALS.DELETE.FILE',
      payload,
      action: MODAL_ACTIONS.DELETE_CHAMPION_FILE,
    });
  }

  goBack(): void {
    if (!this.viewOnly || this.donorChampionView) {
      if (this.selectedStatus !== ChampionStatus.SUMMARY) {
        this.router.navigate([`/${Urls.APP}/${Urls.SETTINGS}/${Urls.MY_CHAMPIONED}/${Urls.CHARITIES}`], {
          queryParams: { tab: this.selectedStatus },
        });
      } else {
        this.router.navigate([`/${Urls.APP}/${Urls.SETTINGS}/${Urls.MY_CHAMPIONED}/${Urls.CHARITIES}`]);
      }
    } else {
      if (this.selectedStatus !== ChampionStatus.SUMMARY) {
        this.router.navigate([`/${Urls.APP}/${Urls.SETTINGS}/${Urls.CHAMPIONS}`], { queryParams: { tab: this.selectedStatus } });
      } else {
        this.router.navigate([`/${Urls.APP}/${Urls.SETTINGS}/${Urls.CHAMPIONS}`]);
      }
    }
  }

  saveDraft(uploadMedia: boolean = false): void {
    if (this.uploader.isUploading) {
      this.toastService.error(ToastrMessages.UPLOAD_IN_PROGRESS);
      return;
    }

    if (this.championForm.pristine && !uploadMedia) {
      this.toastService.info(ToastrMessages.NO_CHANGES);
      return;
    }

    this.championForm.clearValidators();
    this.championForm.clearAsyncValidators();
    this.championForm.updateValueAndValidity();

    const payload = ComputePayloadHelper.linkChampionDraftPayload(
      this.entityUuid,
      this.championForm.getRawValue(),
      this.champion.organizationChampionLinkUuid
    );
    const linkChampionToOrganizationAsDraft$ = this.championService.linkChampionToOrganizationAsDraft(payload).subscribe({
      next: () => {
        this.finished = true;
        this.submitted = true;
        this.toastService.success(ToastrMessages.CHAMPION_ADMIN_SUCCESSFULLY_SAVE_DRAFT);

        if (!uploadMedia) {
          this.goBack();
        }
      },
      error: () => {
        this.toastService.error(ToastrMessages.BASIC_ERROR);
      },
    });

    this.unsubscribes.push(linkChampionToOrganizationAsDraft$);
  }

  saveForm2(): void {
    if (this.uploader.isUploading) {
      this.toastService.error(ToastrMessages.UPLOAD_IN_PROGRESS);
      return;
    }

    this.championForm.setInvalidFields();
    this.championForm.markAllAsTouched();

    if (this.championForm.pristine && !this.championForm.invalidFields.length) {
      this.toastService.info(ToastrMessages.NO_CHANGES);
      return;
    }

    if (this.championForm.invalid) {
      this.toastService.error(ToastrMessages.INVALID_FORM);
      return;
    } else {
      this.saveChampion2();
    }
  }

  saveChampion2(): void {
    const payload = ComputePayloadHelper.linkChampionPayload(
      this.entityUuid,
      this.championForm.getRawValue(),
      this.champion.organizationChampionLinkUuid
    );
    const linkChampionToOrganization$ = this.championService.linkChampionToOrganization(payload).subscribe({
      next: () => {
        this.finished = true;
        this.submitted = true;
        if (this.championUuid) {
          this.toastService.success(ToastrMessages.CHAMPION_ADMIN_SUCCESSFULLY_UPDATED_INFORMATION);
        } else {
          if (this.userService.currentUser.status === UserStatus.COMPLETE) {
            this.toastService.success(ToastrMessages.CHAMPION_ADMIN_SUCCESSFULLY_ADDED_AS_CHAMPION);
          } else {
            this.toastService.success(ToastrMessages.CHAMPION_ADMIN_SUCCESSFULLY_ADDED_AS_CHAMPION_BUT_INCOMPLETE);
          }
        }
        if (this.entityType === ENTITY_TYPE.PORTFOLIO) {
          this.portfolioDataService.resetPortfolio();
        }
        this.goBack();
      },
      error: () => {
        this.toastService.error(ToastrMessages.BASIC_ERROR);
      },
    });

    this.unsubscribes.push(linkChampionToOrganization$);
  }

  computeEngagementYears(): void {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    for (let index = 0; index <= this.yearRange; index++) {
      this.engagementYears.push({
        uuid: (currentYear - index).toString(),
        name: (currentYear - index).toString(),
        disabled: false,
      });
    }
  }

  handleModalResponse(response: any): void {
    const { confirmed, action, mediaControl, payload } = response;
    if (confirmed) {
      switch (action) {
        case MODAL_ACTIONS.DELETE_CHAMPION_FILE:
          this.deleteFileRequest(payload);
          break;
        case MODAL_ACTIONS.CROP:
          this.uploadImagesAfterCrop(payload, mediaControl);
          break;
        default:
          break;
      }
    }
  }

  deleteFileRequest(payload): void {
    const mediaControl: GSFormControl<Media> = payload.mediaControl;
    const deleteFiles$ = this.generalService.deleteFiles(this.entityUuid, mediaControl.value.fileType, mediaControl.value.uuid).subscribe({
      error: () => {
        this.toastService.error(ToastrMessages.BASIC_ERROR);
      },
      complete: () => {
        mediaControl.reset();
        (document.getElementById('championInput') as HTMLInputElement).value = null;
        this.toastService.success(ToastrMessages.REMOVE_FILE);
      },
    });

    this.unsubscribes.push(deleteFiles$);
  }

  uploadImagesAfterCrop(payload: any, mediaControl: GSFormControl): void {
    payload.forEach((b64string) => {
      this.uploadService.uploadFileV3(mediaControl, this.entityUuid, null, b64string);
    });
  }

  setProgress(response: UploadPromiseResponse): void {
    const media = response.fileItem.formData.mediaControl.value as Media;
    media.setProgress(response.response);
  }

  setFileFields(uploadResponse: UploadPromiseResponse): void {
    const mediaControl: GSFormControl = uploadResponse.fileItem.formData.mediaControl;
    if (uploadResponse.response.hasOwnProperty('error') || uploadResponse.response.hasOwnProperty('message')) {
      mediaControl.reset();
      this.toastService.error(ToastrMessages.BASIC_ERROR);
      return;
    }
    mediaControl.patchValue({ ...uploadResponse.response.response, fileName: uploadResponse.fileItem.file.name });
    this.toastService.success(ToastrMessages.FILE_UPLOADED);
  }

  get organizationEngagementControls(): GSFormGroup<ChampionEngagement> {
    return this.championForm.controls.organizationEngagement;
  }

  get mediaControls(): GSFormGroup<ChampionMedia> {
    return this.championForm.controls.championMedia;
  }

  get engagementStartYear(): GSFormControl {
    return this.organizationEngagementControls.controls.engagementStartYear;
  }

  get engagementStartMonth(): GSFormControl {
    return this.organizationEngagementControls.controls.engagementStartMonth;
  }

  get video(): GSFormControl {
    return this.mediaControls.controls.video;
  }

  get image(): GSFormControl {
    return this.mediaControls.controls.image;
  }

  get grantsAwardedNumber(): GSFormControl {
    return this.championForm.controls.grantsAwarded;
  }

  get grantsAwardedCurrency2(): GSFormControl<UUIDName> {
    return this.championForm.controls.grantsAwardedCurrency;
  }

  get testimonial2(): GSFormControl {
    return this.championForm.controls.testimonial;
  }

  get testimonialVisibility(): GSFormControl<UUIDName> {
    return this.championForm.controls.testimonialVisibility;
  }

  get grantsAwardedCurrency(): GSFormControl<UUIDName> {
    return this.championForm.controls.grantsAwardedCurrency;
  }
}
