<div class="champion-container mt-4" *ngIf="championForm">
  <div class="alert alert-danger my-2" role="alert" *ngIf="championForm?.invalidFields?.length">
    {{ 'ADMIN.SETUP.ORGANIZATION.GENERAL.INCOMPLETE_FIELDS' | translate }}
    <ul class="mt-2">
      <li *ngFor="let label of championForm.invalidFields">{{ label | translate }}</li>
    </ul>
  </div>

  <form [formGroup]="championForm" class="w-100 bg-white position-relative px-lg-4" autocomplete="off" list="autocompleteOff">
    <div class="row mb-2">
      <div class="admin-input col col-md-12">
        <div class="label mb-0">{{ 'CATALOG.CHAMPION.GENERAL.CHAMPION_THIS_PROJECT_2' | translate }} {{ organizationName }}</div>
        <h4
          class="mb-0"
          [innerHTML]="'CATALOG.CHAMPION.GENERAL.CHAMPION_THIS_PROJECT_DESCRIPTION' | translate: { organization: organizationName }"
        ></h4>
      </div>
    </div>

    <div class="row mb-2">
      <div class="admin-input col col-md-12">
        <div class="label mb-0">{{ 'CATALOG.CHAMPION.GENERAL.CHAMPION_VISIBILITY' | translate }}</div>
        <h4 class="mb-0" *ngIf="!hasChampionEntity">{{ 'CATALOG.CHAMPION.GENERAL.CHAMPION_PERSONAL_NAME' | translate }} {{ userFullName }}</h4>
        <h4 class="mb-0" *ngIf="hasChampionEntity">{{ 'CATALOG.CHAMPION.GENERAL.CHAMPION_ENTITY_NAME' | translate }} {{ entityName }}</h4>
      </div>
    </div>

    <ng-container [formGroupName]="organizationEngagementControls.key">
      <!-- Charity Engagement -->
      <div class="row mb-2">
        <div class="admin-input col col-md-12">
          <div class="label">{{ organizationEngagementControls.label | translate }} *</div>
        </div>

        <div class="engagement-field-container">
          <div class="admin-input col col-md-1">
            <div class="label">{{ engagementStartYear.label | translate }}</div>
          </div>

          <div class="admin-input col col-md-5">
            <div class="d-flex">
              <mat-form-field appearance="outline">
                <mat-select
                  [id]="engagementStartYear.key"
                  [formControlName]="engagementStartYear.key"
                  [compareWith]="compareWithFn"
                  [disabled]="viewOnly"
                >
                  <mat-option [id]="type.name" *ngFor="let type of engagementYears" [value]="type">{{ type.name }} </mat-option>
                </mat-select>
                <mat-error *ngFor="let err of engagementStartYear.controlErrors">
                  <mat-error *ngIf="engagementStartYear.hasError(err.type)">
                    {{ err.text | translate }}
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="admin-input col col-md-6">
            <div class="d-flex">
              <mat-form-field appearance="outline">
                <mat-select
                  [id]="engagementStartMonth.key"
                  [formControlName]="engagementStartMonth.key"
                  [compareWith]="compareWithFn"
                  [disabled]="viewOnly"
                >
                  <mat-option [id]="engagementMonth.name" *ngFor="let engagementMonth of engagementMonths" [value]="engagementMonth"
                    >{{ engagementMonth.name | translate }}
                  </mat-option>
                </mat-select>
                <mat-error *ngFor="let err of engagementStartMonth.controlErrors">
                  <mat-error *ngIf="engagementStartMonth.hasError(err.type)">
                    {{ err.text | translate }}
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Grants Awarded -->
    <div class="row mb-2" *ngIf="showGrantsAwarded">
      <div class="admin-input col col-md-8" [class.disabled]="grantsAwardedNumber.disabled">
        <div class="label">
          {{ grantsAwardedNumber.label | translate }} * <span class="optional">({{ grantsAwardedNumber.placeholder | translate }})</span>
        </div>
        <div class="d-flex">
          <mat-form-field appearance="outline">
            <input
              matInput
              [id]="grantsAwardedNumber.key"
              currencyMask
              [options]="{
                prefix: setCurrencySymbol(grantsAwardedCurrency, currencies),
                suffix: '',
                thousands: thousandsSeparator,
                decimal: decimalSeparator,
                precision: 2,
                inputMode: inputMode,
                nullable: true,
                min: 0,
                align: 'left'
              }"
              [placeholder]="grantsAwardedNumber.placeholder | translate"
              [formControlName]="grantsAwardedNumber.key"
              [disabled]="viewOnly"
              [readonly]="viewOnly"
            />
            <mat-error *ngFor="let err of grantsAwardedNumber.controlErrors">
              <mat-error *ngIf="grantsAwardedNumber.hasError(err.type)">
                {{ err.text | translate }}
              </mat-error>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="col col-md-4">
        <div class="admin-input" [class.disabled]="grantsAwardedCurrency2.disabled">
          <div class="label">{{ grantsAwardedCurrency2.label | translate }} *</div>
          <mat-form-field appearance="outline">
            <mat-select
              [id]="grantsAwardedCurrency2.key"
              [formControlName]="grantsAwardedCurrency2.key"
              [compareWith]="compareWithFn"
              [disabled]="viewOnly"
            >
              <mat-option [id]="type.name" *ngFor="let type of currencies" [value]="type">{{ type.name }} </mat-option>
            </mat-select>
            <mat-error *ngFor="let err of grantsAwardedCurrency2.controlErrors">
              <mat-error *ngIf="grantsAwardedCurrency2.hasError(err.type)">
                {{ err.text | translate }}
              </mat-error>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <!-- Testimonial -->
    <div class="admin-textarea">
      <div class="d-flex justify-content-between align-items-center">
        <div class="label">{{ testimonial2.label | translate }}</div>
      </div>
      <div class="small-text text-gray">{{ testimonial2.placeholder | translate | translateCut: 0 }}</div>
      <div class="small-text text-gray ml-2">{{ testimonial2.placeholder | translate | translateCut: 1 }}</div>
      <div class="small-text text-gray ml-2">{{ testimonial2.placeholder | translate | translateCut: 2 }}</div>
      <div class="small-text text-gray ml-2">{{ testimonial2.placeholder | translate | translateCut: 3 }}</div>
      <mat-form-field appearance="outline">
        <textarea
          cdkTextareaAutosize
          cdkAutosizeMaxRows="10"
          matInput
          rows="2"
          [id]="testimonial2.key"
          [formControlName]="testimonial2.key"
          [maxLength]="testimonial2.max"
          [readonly]="viewOnly"
          [value]="testimonial2.value | translate"
        ></textarea>
        <mat-error *ngFor="let err of testimonial2.controlErrors">
          <mat-error *ngIf="testimonial2.hasError(err.type)">
            {{ err.text | translate }}
          </mat-error>
        </mat-error>
      </mat-form-field>
    </div>

    <!-- Upload media -->
    <div class="admin-input my-3">
      <div>
        <div class="label w-75">{{ mediaControls.label | translate }}</div>
        <div class="small-text text-gray" *ngIf="mediaControls.placeholder">{{ mediaControls.placeholder | translate }}</div>
        <button
          id="addChampionVideo"
          class="document-button"
          (click)="championMedia.click()"
          type="button"
          mat-stroked-button
          [hidden]="video.value.uuid || video.value.pendingUpload || image.value.uuid || image.value.pendingUpload"
        >
          {{ 'CATALOG.CHAMPION.NEW_CHAMPION_FORM.LABELS.MEDIA_OPTIONAL_TEXT' | translate }}
          <i class="fas fa-video"></i>
        </button>

        <input
          id="championInput"
          #championMedia
          ng2FileSelect
          hidden
          [uploader]="uploader"
          type="file"
          accept=""
          (change)="mediaSelected($event)"
          [disabled]="viewOnly"
        />

        <ng-container *ngIf="mediaControls.invalid && (mediaControls.dirty || mediaControls.touched)">
          <mat-error *ngFor="let err of mediaControls.controlErrors">
            <mat-error class="error mt-1" *ngIf="mediaControls.hasError(err.type)">
              {{ err.text | translate }}
            </mat-error>
          </mat-error>
        </ng-container>
      </div>

      <!-- Champion Video -->
      <app-upload-progress-bar [pending]="video.value.pendingUpload" [progress]="video.value.progress"></app-upload-progress-bar>
      <app-media-info
        *ngIf="video.value.fileName"
        [media]="video.value"
        [shouldDisable]="viewOnly"
        (deleteFile)="deleteChampionMediaFile(video, championMedia)"
      ></app-media-info>
      <ng-container *ngIf="!video.value.isFormatValid">
        <div class="small-text mat-error mb-2">{{ allowedFileTypes.videoValidFormatsMessage }}</div>
      </ng-container>

      <!-- Champion Image -->
      <app-upload-progress-bar [pending]="image.value.pendingUpload" [progress]="image.value.progress"></app-upload-progress-bar>
      <app-media-info
        *ngIf="image.value.fileName"
        [media]="image.value"
        [shouldDisable]="viewOnly"
        (deleteFile)="deleteChampionMediaFile(image, championMedia)"
      ></app-media-info>
      <ng-container *ngIf="!image.value.isFormatValid">
        <div class="small-text mat-error mb-2">{{ allowedFileTypes.imageValidFormatsMessage }}</div>
      </ng-container>
    </div>

    <!-- Testimonial visibility -->
    <div class="admin-input mb-2">
      <div class="label">{{ testimonialVisibility.label | translate }} *</div>
      <mat-form-field appearance="outline">
        <mat-select
          [id]="testimonialVisibility.key"
          [formControlName]="testimonialVisibility.key"
          [compareWith]="compareWithFn"
          [disabled]="viewOnly"
        >
          <mat-option [id]="type.name" *ngFor="let type of testimonialExpirations" [value]="type">{{ type.name | translate }} </mat-option>
        </mat-select>
        <mat-error *ngFor="let err of testimonialVisibility.controlErrors">
          <mat-error *ngIf="testimonialVisibility.hasError(err.type)">
            {{ err.text | translate }}
          </mat-error>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="d-flex justify-content-end align-items-end mb-2">
      <div class="text-nowrap mb-1">
        <button
          id="cancel"
          type="button"
          class="mat-outline-button gray-border mr-2 lh-30"
          mat-stroked-button
          [disableRipple]="true"
          (click)="goBack()"
          *ngIf="viewOnly"
        >
          {{ 'GENERAL.GENERIC_BUTTONS.BACK' | translate }}
        </button>
        <button
          id="cancel"
          type="button"
          class="mat-outline-button gray-border mr-2 lh-30"
          mat-stroked-button
          [disableRipple]="true"
          (click)="goBack()"
          *ngIf="!viewOnly"
        >
          {{ 'GENERAL.GENERIC_BUTTONS.CANCEL' | translate }}
        </button>
        <button
          id="submit"
          type="submit"
          class="mat-main-button lh-30 mr-2"
          mat-flat-button
          color="accent"
          [disableRipple]="true"
          [disabled]="championForm.pristine"
          (click)="saveDraft()"
          *ngIf="showSaveAsDraft"
        >
          {{ 'GENERAL.GENERIC_BUTTONS.SAVE_DRAFT' | translate }}
        </button>
        <button
          id="saveChangesButton"
          type="submit"
          class="mat-main-button lh-30"
          mat-flat-button
          color="primary"
          [disableRipple]="true"
          [disabled]="!championForm.valid"
          (click)="saveForm2()"
          *ngIf="!viewOnly"
        >
          {{
            (showSaveAsDraft
              ? 'CATALOG.CHAMPION.GENERAL.SAVE_FORM'
              : showSubmitBtn
              ? 'GENERAL.GENERIC_BUTTONS.SUBMIT'
              : 'GENERAL.GENERIC_BUTTONS.RESUBMIT'
            ) | translate
          }}
        </button>
      </div>
    </div>
  </form>
</div>
